import { Controller } from "@hotwired/stimulus"

// Constants used to ensure the sum is always 100. 
const REQUIRED_TOTAL = 100;
const DEFAULT_PERCENTAGE = 50;

// Connects to data-controller="percentage"
export default class extends Controller {
  static targets = [ "field1", "field2" ];
  
  initialize() {
    // Ensure that both fields have a default value
    if (!this.field1Target.value) this.field1Target.value = DEFAULT_PERCENTAGE;
    if (!this.field2Target.value) this.field2Target.value = DEFAULT_PERCENTAGE;
    
    this._isUpdating = false;
  }

  // When the user updates one field, update the complementary field.
  updateComplement({ target }) {
    if (this._isUpdating) return;
    
    this._isUpdating = true;
    const inputValue = parseFloat(target.value) || 0;
    const complement = REQUIRED_TOTAL - inputValue;

    if (target === this.field1Target) {
      this.field2Target.value = complement;
    } else if (target === this.field2Target) {
      this.field1Target.value = complement;
    }
    this._isUpdating = false;
  }
}
